@import "designSystem/colors.scss";

.manage-inventory-page {
    .manage-inventory-page-wrapper {
        padding: 0 130px;

        .go-back {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: $font-primary;
            font-weight: 600;
            font-size: 14px;
            width: fit-content;
            
            &:hover {
                cursor: pointer;

                span {
                    text-decoration: underline;
                }
            }

            svg {
                font-size: 18px;
            }

            span {
                margin-left: 5px;
            }
        }

        .questionnaire-container {
            width: 100%;
            margin: 50px auto 0 auto;
        }
    }
}
