@import "designSystem/colors.scss";

.inventory-page {
    width: 90% !important;
    max-width: 90% !important;

    .main-layout-section {
        max-width: 100% !important;
        width: auto !important;

        .content {
            padding: 30px 30px !important;
        }
    }

    .inventory-page-wrapper {
        overflow: auto;
        height: 100%;

        .inventory-page-content {
            display: inline-block;
            min-width: 100%;

            .inventory-header {
                margin-bottom: 10px;
                min-width: 100%;

                h2 {
                    color: $primary-dark;
                    margin: 0;
                    border-bottom: 1px solid $border-color;
                    padding-bottom: 5px;
                    margin-bottom: 5px;
                    font-size: 12px;
                }

                .inventory-details {
                    display: flex;
                    justify-content: flex-end;
                    font-size: 12px;

                    .inventory-infos {
                        display: flex;
                        align-items: center;

                        .info {
                            margin-right: 5px;
                            padding-right: 5px;

                            &:not(:last-of-type) {
                                border-right: 1px solid black;
                            }
                        }
                    }

                    .inventory-actions {
                        display: flex;
                        align-items: center;

                        .action {
                            display: flex;
                            align-items: center;
                            color: $font-primary;
                            text-decoration: none;

                            svg {
                                font-size: 16px;
                            }

                            &:hover {
                                cursor: pointer;
                                opacity: 0.7;
                            }

                            span {
                                margin-left: 3px;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }

            .inventory-table {
                thead {
                    .table-head {
                        .table-head-column-name {
                            width: 100%;
                            min-width: 0;
                            white-space: normal;
                        }

                        .table-head-content {
                            display: flex;
                            align-items: center;
                            width: 100%;
                            height: 30px;
                        }

                        .table-head-input {
                            height: 20px;
                            width: 100%;

                            .input-text {
                                width: 100%;
                                background-color: $background;
                            }

                            .custom-select {
                                .dropdown-heading {
                                    height: 17px !important;

                                    .panel-content {
                                        .item-renderer {
                                            display: flex;
                                            align-items: center;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .center-row {
                    text-align: center;
                }

                .danger-wrapper {
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .danger {
                        width: 9px;
                        height: 20px;
                        background-color: gray;
                        margin-right: 2px;

                        &.level0 {
                            width: 0;
                        }

                        &.level1 {
                            background-color: #ada4a0;
                        }
                        &.level2 {
                            background-color: #4d3f39;
                        }
                        &.level3 {
                            background-color: #edb195;
                        }
                        &.level4 {
                            background-color: #e58f68;
                        }
                    }
                }
            }
        }
    }
}
