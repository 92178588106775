@import "designSystem/colors.scss";

.inventory-check-wrapper {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    h2 {
        margin: 0;
        font-size: 13px;
    }

    p {
        margin: 10px 0 0 0;
        font-size: 13px;
    }

    .error {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: 23px;

        button {
            margin-top: 20px;
            width: 90px;
            height: 40px;
        }
    }

    .inventory-check-content {
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        .inventory-questions-tab {
            padding: 0;
            width: 100%;
            margin: 0 auto;
        }
    }
}

@media only screen and (max-width: 1070px) {
    .inventory-check-wrapper {
        .inventory-check-content {
            .inventory-questions-tab {
                padding: 0;
                width: 100%;
                margin: unset;
            }
        }
    }
}

@media only screen and (max-width: 1500px) {
    .inventory-questions-tab {
        padding: 0;
    }
}
