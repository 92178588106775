@import "designSystem/colors.scss";

.multi-select-component {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    &.disabled {
        pointer-events: none;

        .multi-select-wrapper {
            background-color: #dadada !important;
            border-color: #b3b3b3 !important;
            cursor: not-allowed !important;
            color: #a3a3a3 !important;
        }
    }

    .multi-select-wrapper {
        color: $font-primary;
        border: 1px solid $border-color;
        box-sizing: border-box;
        font-family: Arial;
        background-color: #f7f7f7;
        height: 28px;
        display: flex;
        align-items: center;
        font-size: 13px;
        font-weight: 400;
        padding: 0px 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 3px;

        &:hover {
            cursor: pointer;
        }

        .multi-select-input {
            flex: 1;
        }

        .multi-select-arrow {
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
        }
    }

    .multi-select-dropdown {
        margin-top: 5px;
        border: 1px solid $border-color;
        border-radius: 5px;
        position: absolute;
        width: 100%;
        overflow: hidden;
        box-shadow: 0 0 8px #e0e0e0;
        max-height: 182px;
        overflow: auto;
        z-index: 10;

        .multi-select-option {
            padding: 7px 10px;
            font-size: 13px;
            font-weight: 400;
            background-color: #fff;
            color: $font-primary;
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .checkbox {
                width: 15px;
                height: 15px;
                border: 1px solid $border-color-dark;
                border-radius: 3px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 10px;
                font-size: 13px;

                &.checked {
                    background-color: $border-color-dark;
                    color: white;
                }
            }

            .value {
                flex: 1;
                display: flex;
                align-items: center;
            }

            &:hover {
                cursor: pointer;
                background-color: #f1f1f1;
            }
        }
    }
}
