@import "designSystem/colors.scss";

.questionnaire-component {
    width: 100%;

    .section-data-wrapper {
        margin-bottom: 60px;

        .section-title {
            margin-bottom: 20px;
            font-weight: 500;
            text-decoration: underline;
        }

        .section-questions {
            .question {
                margin-bottom: 30px;
                display: flex;

                &.question-section {
                    margin-bottom: 15px;
                }

                .question-title {
                    font-size: 13px;
                    word-break: break-word;

                    span {
                        margin-left: 5px;
                    }
                }

                .section-title-content {
                    display: flex;
                    align-items: center;
                    color: $font-primary;

                    .section-title-icon {
                        font-size: 16px;
                        margin-right: 6px;
                    }

                    .section-title-text {
                        font-weight: 600;
                        font-size: 15px;
                    }
                }

                .question-content {
                    flex: 1;
                    min-width: 0;

                    .question-body {
                        margin-top: 8px;
                        display: flex;
                        flex-direction: column;

                        .flex {
                            display: flex;
                            align-items: center;

                            select,
                            input {
                                width: 100%;
                                padding: 3px 7px;
                                border-radius: 3px;
                                height: 28px;
                                background-color: #f7f7f7;
                            }

                            textarea {
                                width: 100%;
                                padding: 7px;
                                border-radius: 3px;
                                background-color: #f7f7f7;
                                resize: none;

                                &:focus {
                                    outline: 0;
                                }
                            }

                            button, a {
                                margin-left: 10px;
                                flex-shrink: 0;

                                div {
                                    white-space: nowrap;
                                }
                            }
                        }

                        .filename {
                            background-color: #f7f7f7;
                        }

                        .error-message {
                            font-size: 12px;
                            font-weight: 600;
                            color: #e43a3a;
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
    }

    .form-actions {
        display: flex;
        justify-content: flex-end;

        button {
            margin-left: 15px;
        }
    }
}
